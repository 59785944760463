$play-color: white;
$ratio: 0.6;
$height: 10px;
$angle-height: $height * $ratio;

.play-button {
    margin-bottom: $height / 2;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: $height * (1 + $ratio * 0.5);

    border-color: transparent transparent transparent $play-color;
    cursor: pointer;

    // play state
    border-style: solid;
    border-width: $angle-height 0 $angle-height $height;

    &.paused {
        margin-top: $ratio;
        border-style: double;
        border-width: 0 0 0 $height;
    }

    &:hover {
        border-color: transparent transparent transparent $play-color;
    }
}